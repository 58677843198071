<template>
  <div class="bg pt-4">
    <div class="container">
      <div class="top-links">
          <div class="logo text-center d-sm-none d-block">
              <router-link to="/">
                  <img src="/img/logo_selcare_512.png" class="home-logo" alt="" loading="lazy">
              </router-link>
          </div>
      </div>

      <div v-if="startSection == false" class="main-eprescription mb-5">
        <div class="row">
            <div class="col-md-6 col-sm-12 d-md-block d-sm-none d-none">
                <div class="p-3">
                    <img class="d-block w-100" src="/img/telemedecine_1.jpeg" />
                    <img class="d-block w-100" src="/img/telemedecine_2.jpeg" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="main-heading">
                  <h2>Our Services</h2>
              </div>
              <div class="main-description">
                  <p>
                      Telemedicine allows discussion of doctor and patients remotely. Non-emergency conditions can be discussed 
                      with doctor without the need of a clinic visit. This makes it easier for patients, thus saving time and money.
                  </p>
                  <p>
                    In Selcare telemedicine portal, real time video communication is used for discussion between doctor and patient. 
                    Once a diagnosis has been made an e-prescription can be issued for patients to collect medicines at pharmacies. 
                    If needed, Medical Certificate can also be issued for the patient. This is useful for monitoring long-standing diseases 
                    or treatment of simple, minor conditions. Certain limitation exists in telemedicine, this means that not all cases can be 
                    treated remotely. Certain cases need proper physical follow-ups. Some conditions needed to be examined properly by doctors. 
                    Do contact us for further information.
                  </p>
              </div>
            </div>
        </div>

          <!-- <div class="main-heading">
              <h2>Our Services</h2>
          </div>
          <div class="main-description">
              <p>
                  Telemedicine allows discussion of doctor and patients remotely. Non-emergency conditions can be discussed 
                  with doctor without the need of a clinic visit. This makes it easier for patients, thus saving time and money.
              </p>
              <p>
                In SelCare telemedicine portal, real time video communication is used for discussion between doctor and patient. 
                Once a diagnosis has been made an e-prescription can be issued for patients to collect medicines at pharmacies. 
                If needed, Medical Certificate can also be issued for the patient.This is useful for monitoring long-standing diseases 
                or treatment of simple, minor conditions. Certain limitation exists in telemedicine, this means that not all cases can be 
                treated remotely. Certain cases need proper physical follow-ups. Some conditions needed to be examined properly by doctors. 
                Do contact us for further information.
              </p>
          </div> -->
          <!-- <div class="main-banner d-none d-md-block shadow-sm">
              <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner">
                      <div class="carousel-item active">
                          <img class="d-block w-100" src="/img/telemedecine_1.jpeg" alt="First slide">
                      </div>
                      <div class="carousel-item">
                          <img class="d-block w-100" src="/img/telemedecine_2.jpeg" alt="First slide">
                      </div>
                  </div>
                  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                  </a>
              </div>
          </div> -->
          <div class="pt-5 text-center">
              <button v-if="currentCustomer" class="btn btn-primary btn-get-started" @click="startSection = true">Consult Now</button>
              <button v-else class="btn btn-primary btn-get-started" @click="$router.push('/auth/login')">Consult Now</button>
          </div>
      </div>
      <div v-else
        class="
          w-100
          d-flex
          justify-content-center
          align-items-start
          px-3
        "
        style="height: calc(100vh - 72px)"
      >
      <!-- style="height: calc(100vh - 72px)" -->
        <div style="padding-top: 1rem; padding-bottom: 6rem">
          <div v-if="currentCustomer && currentCustomer.user">
            <h3 class="fw-strong ff-title mb-0 mt-4">
              Hello, {{ currentCustomer.user.firstname? currentCustomer.user.firstname : currentCustomer.user.username }}!
            </h3>
            <p class="ff-desc text-muted mb-5">
              Serving your
              <span class="text-uppercase text-dark fw-strong">health</span> needs
              is our priority.
            </p>
          </div>
          <div
            class="text-muted text-right w-100 my-2"
            v-if="prescriptions.length > 0"
          >
            <router-link
              :to="'/e-prescription/list-prescription'"
              class="text-muted small"
            >
              View my consultations
              <!-- <i class="fas fa-arrow-right align-middle ml-1" /> -->
            </router-link>
          </div>

          <div class="card border-1 card-radius custom-card-bg-info">
            <div class="card-body">
              <div class="position-relative d-block">
                <div class="mb-4">
                  <img src="../../../assets/files.svg" />
                </div>
              </div>
              <div class="">
                <h4 class="mb-4 fw-strong ff-title">
                  {{
                    prescriptions.length > 0
                      ? "Need another Telemedicine?"
                      : "Create New Telemedicine?"
                  }}
                </h4>
                <p class="text-muted mb-5 ff-desc">
                  Are you concerned that you are not taking your medications as
                  prescribed? Afraid not! Consult with our medical experts online
                  to receive advice right away. Do not wait. Keep in mind that you
                  could save your own life
                </p>
                <router-link to="/e-prescription/choose-doctor">
                  <button
                    type="button"
                    class="
                      btn btn-light
                      align-middle
                      border-secondary border-0
                      p-0
                    "
                    style="color: #b8514a; background-color: transparent"
                  >
                    {{
                      prescriptions.length > 0
                        ? " New Consultation"
                        : "Let's get started"
                    }}

                    <i class="ri-arrow-right-line ml-2 align-middle"></i>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <!-- <div class="card border-1 my-3 card-radius custom-card-bg-danger">
            <div class="card-body">
              <div class="position-relative d-block">
                <h4 class="mb-4 fw-strong ff-title">
                  Would you like to book an appointment?
                </h4>
                <p class="text-muted mb-5 ff-desc">
                  Make an appointment with us today with our medical experts!<br />
                  You can plan your appointment at your convenience, online!
                </p>
                <button
                  type="button"
                  class="btn btn-light align-middle border-secondary border-0 p-0"
                  @click="goToAppointment"
                  style="color: #5789a4; background-color: transparent"
                >
                  Clinic Appointment
                  <i class="ri-calendar-line ml-2 align-middle"></i>
                </button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import API from "../../../utils/API";
// import API from "./../../../utils/API";

export default {
  name: "userProfile",
  data() {
    return {
      startSection: false,
      doctor: null,
      expiryDate: null,
      display: false,
      prescriptions: [],
    };
  },
  computed: {
    ...mapGetters(["currentCustomer", "consultation"]),
  },
  methods: {
    ...mapActions(["setActiveConsultation"]),
    goToAppointment() {
      this.$router.push({
        path: "/clinic-appointment/manage",
      });
    },
    goToPrescription(_prescription_id) {
      this.$router.push({
        path: `/e-prescription/check/${_prescription_id}`,
      });
    },
    getDatetimeFormat(_datetime) {
      return moment(_datetime).format("DD MMM YYYY");
    },
  },
  async mounted() {
    const response = await API.get(`/prescriptions?customer_eq=${this.currentCustomer.user.customer.id}`);
    const dataSource = response.data;
    // let newData = [];
    // if (dataSource.length > 0) {
    //   for (let i = 0; i < dataSource.length; i++) {
    //     if (dataSource[i].customer && dataSource[i].customer.user === this.currentCustomer.user.id) {
    //       newData.push(dataSource[i]);
    //     }
    //   }
    // }
    // this.prescriptions = newData.filter((item) => item.consultation);
    this.prescriptions = dataSource.filter((item) => item.consultation);

    // await this.setActiveConsultation({
    //   userID: this.currentCustomer.user.id,
    // });

    // if (
    //   this.consultation &&
    //   this.consultation.status &&
    //   this.consultation.status == "accepted"
    // ) {
    //   this.$router.push({
    //     path: "/e-prescription/consultation",
    //   });
    // } else if (
    //   this.consultation &&
    //   this.consultation.status &&
    //   this.consultation.status == "pending"
    // ) {
    //   this.$router.push({
    //     path: "/e-prescription/choose-doctor",
    //   });
    // } else {
    //   this.display = true;
    // }
  },
};
</script>

<style scoped>
.bg {
  position: relative;
  /* display: flex; */
  /* height: calc(100vh - 72px); */
  background-image: url(../../../assets/Vector.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-content: center;
}

/* .btn,
a,
.card,
h4 {
  font-family: "Karla", serif !important;
} */

.card {
  max-width: 500px;
}

.bg-card-gradient {
  background-image: linear-gradient(30deg, #9e3838, #d98556);
}

.card.card-radius {
  overflow: hidden;
  border-radius: 0.8rem !important;
}

.card-custom {
  width: 500px;
}

.custom-danger {
  background-color: #fef3f2;
  color: #b42318;
}

.custom-success {
  background-color: #ecfdf3;
  color: #027a48;
  font-weight: 500;
}

.custom-card-bg-info {
  background-color: #fef3f2;
  border-color: #fecdca;
}

.custom-card-bg-danger {
  background-color: #f0f9ff;
  border-color: #b9e6fe;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Main e-prescription Page */
.main-eprescription {
    
}
.main-eprescription .main-heading{
    text-align: center;
}
.main-eprescription .main-heading h2,
.main-eprescription .main-heading h3{
    color: #a91a17;
    font-weight: bolder;
}
.main-eprescription .main-description{
    text-align: center;
}
.main-eprescription .main-description p{
    font-weight:400;
    text-align: justify;
}
.main-eprescription .main-banner{
    border: 2px solid #afafaf30;
}
.btn.btn-primary.btn-get-started {
    width: 50%;
    padding: 15px 10px;
    border-radius: 20px;
    background-color: #a91a17;
    border: unset;
}
/* Main e-prescription Page */

/** Media Queries */
@media (max-width: 575px) {

    .top-links {
        margin-bottom: 20px;
    }
    .top-links > a {
        float: left;
        margin-top: 10px;
    }
    .top-links .logo {
        /* margin-right: 20px; */
    }
    .top-links .home-logo {
        height: 75px;
    }
}
</style>